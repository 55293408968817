@import "typography";
@import "palette";
@import "metrics";



.tippy-box[data-theme~="nachas"] {
  font-family: $font-family-base;
  //color: var(--color--text);
  color: var(--color--brand-light);
  box-shadow: 0 4px 8px var(--color--system-shadow);
  font-size: var(--metric-x3-5);
  font-weight: 400;
  line-height: var(--metric-x6);
  letter-spacing: 0;
  text-align: left;

  //background-color: var(--color--text-light);
  background-color: var(--color--brand-dark);
  border-radius: var(--metric-x2);
  position: relative;
  max-width: 700px !important;/*rewrite plugin sitting */

  .tippy-content {
    margin: 0;
    //padding: var(--size--spacing-x5);
    padding: var(--metric-x2) var(--metric-x3);
    white-space: wrap;
  }

  .tippy-arrow {
    // content: " ";
    // border-width: 4px;
    // border-style: solid;
    // border-color: var(--color--brand-dark) transparent var(--color--brand-dark) transparent;

    //width: var(--size--spacing-x6);
    //height: var(--size--spacing-x3);
    color: var(--color--brand-dark);
    background-color: var(--color--brand-dark);
    //box-shadow: 0 -13px 10px 0px rgb(0 0 0 / 7%);
    //
    //::before {
    //  transform: scale(1.5);
    //}
  }

  &[data-placement^="bottom"] {
    //margin-top: var(--size--spacing-x4);
    //
    //.tippy-arrow:before {
    //  top: calc(var(--size--spacing-x3) * -1);
    //  border-width: 0 var(--size--spacing-x3) var(--size--spacing-x3);
    //}

    .tippy-arrow {
      bottom: 100%;  /* At the top of the tooltip */
    }
  }

  &[data-placement^="top"] {
    //margin-bottom: var(--size--spacing-x4);
    //
    //.tippy-arrow {
    //  box-shadow: none;
    //}
    //
    //.tippy-arrow:before {
    //  bottom: calc(var(--size--spacing-x3) * -1);
    //  border-width: var(--size--spacing-x3) var(--size--spacing-x3) 0;
    //}
  }
}
