$colors: brand-dark, brand-light, brand-cta, brand-tint, brand-dark-tint, brand-light-tint, brand-highlight, brand-grey,
system-green, system-yellow, system-pink, system-blue, system-orange;


:root {
  // --ion-color-rose: rgb(131, 24, 67);
  // --ion-color-rose-rgb: 131, 24, 67;
  // --ion-color-rose-contrast: #ffffff;
  // --ion-color-rose-contrast-rgb: 255, 255, 255;
  // --ion-color-rose-shade: #73153b;
  // --ion-color-rose-tint: #8f2f56;

  --color--brand-dark:          #191e32;
  --color--brand-light:         #FFFFFF;
  --color--brand-grey:         #E6E1D7;
  --color--brand-cta:           #27a69a;
  --color--brand-background:    #23283c;
  --color--brand-cta-disabled:  #233747;
  --color--brand-cta-hover:     #24555E;
  --color--brand-tint:          #555A6E;
  --color--brand-dark-tint:     #787D91;
  --color--brand-light-tint:    #373C4B;
  --color--brand-highlight:     #CD3264;
  --color--brand-light-overlay: rgba(255, 255, 255, 0.75);
  --color--brand-medium-overlay: rgba(128, 128, 128, 0.75);
  --color--brand-dark-overlay:  rgba(25, 30, 50, 0.75);
  --color--brand-item-highlight: rgba(55, 60, 75, 0.35);

  --color--system-green:        #27A69A;
  --color--system-yellow:       #E1C84B;
  --color--system-pink:         #CD3264;
  --color--system-blue:         #1A76D2;
  --color--system-orange:       #EF5351;

  --color--system-shadow:       rgba(24, 30, 50, 0.36);
  --color--darkOverlay:         rgba(25, 30, 50, 0.75);

    // Text Colors
    --color--text-error: var(--color--system-pink);


    // Forms

    --color--form-border-inactive: var(--color--brand-tint);
    --color--form-border-active: var(--color--brand-cta);
    --color--form-border-disabled: var(--color--brand-tint);
    --color--form-border-error: var(--color--system-pink);
    --color--form-border-notch-active: var(--color--brand-light);

    // --color--form-background-autofill: rgba(var(--color--brand-light), 8%);

    --color--form-text: #{c(color--dark)};
    --color--form-text-inactive: var(--color--brand-tint);
    --color--form-text-active: var(--color--brand-cta);
    --color--form-text-disabled: var(--color--brand-dark-tint);
    --color--form-text-error: var(--color--system-pink);

    --shadow--medium: 0 6px 18px var(--color--system-shadow);
}



.shadow-drag {
  box-shadow: 0 2px 12px var(--color--system-shadow);
}

.shadow-tiny {
  box-shadow: 0 4px 8px var(--color--system-shadow);
}

.shadow-small {
  box-shadow: 0 4px 12px var(--color--system-shadow);
}

.shadow-medium {
  box-shadow: 0 6px 18px var(--color--system-shadow);
}

.shadow-large {
  box-shadow: 0 8px 24px var(--color--system-shadow);
}


