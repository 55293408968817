:root {
  --metric-x0-25:1px;
  --metric-x0375:1.5px;
  --metric-x05:  2px;
  --metric-x0-75:  3px;
  --metric-x1:   4px;
  --metric-x1-25: 5px;
  --metric-x1-75: 7px;
  --metric-x2:   8px;
  --metric-x2-5: 10px;
  --metric-x2-75:11px;
  --metric-x3:   12px;
  --metric-x3-25:13px;
  --metric-x3-5: 14px;
  --metric-x3-75:15px;
  --metric-x4:   16px;
  --metric-x4-5: 18px;
  --metric-x5:   20px;
  --metric-x5-5: 22px;
  --metric-x6:   24px;
  --metric-x7:   28px;
  --metric-x7-5: 30px;
  --metric-x8:   32px;
  --metric-x8-5: 34px;
  --metric-x9:   36px;
  --metric-x10:  40px;
  --metric-x10-5:42px;
  --metric-x12:  48px;
  --metric-x12-5:  50px;
  --metric-x14:  56px;
  --metric-x15:  60px;
  --metric-x17:  68px;
  --metric-x18:  72px;
  --metric-x21-25:  85px;
  --metric-x24:  96px;
  --metric-x25:  100px;
  --metric-x28-5: 114px;
  --metric-x30: 120px;
  --metric-x32-75: 131px;
  --metric-x36:  144px;
  --metric-x36-25:145px;
  --metric-x43-75:175px;
  --metric-x45:180px;
  --metric-x50:  200px;
  --metric-x52:  208px;
  --metric-x55:  220px;
  --metric-x65-5:262px;
  --metric-x80:  320px;
  --metric-x112-5:  450px;
  --metric-x150:  600px;


  // Header logo
  --size--header-logo: var(--metric-x15);

  // Border
  --size--border-1-5: var(--metric-x0375);

  // Forms

  --size--form-base-height: var(--metric-x12);
  --size--form-base-width: 100%;
  --size--form-base-margin: var(--metric-x2);
  --size--form-base-border: var(--metric-x0375);
  --size--form-base-border-radius: var(--metric-x1);
  --size--form-icon: var(--metric-x4);
  --size--form-base-padding: 0 var(--metric-x3);

  // Options
  --size--options-max-height: var(--metric-x52);

  // Button

  --size--button-loader-size: var(--metric-x2);
  --size--button-loader-child-position: var(--metric-x1);

  // Icons

  --size--icon-xs: var(--metric-x1);
  --size--icon-pico: var(--metric-x2);
  --size--icon-micro: var(--metric-x2-5);
  --size--icon-tiny: var(--metric-x3);
  --size--icon-small: var(--metric-x4);
  --size--icon-medium: var(--metric-x5);
  --size--icon-large: var(--metric-x6);
  --size--icon-xl: var(--metric-x8);
  --size--icon-huge: var(--metric-x12);
  --size--icon-mhuge: var(--metric-x14);
  --size--icon-xhuge: var(--metric-x18);
  --size--icon-input: var(--metric-x4-5);


}
