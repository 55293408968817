$font-family-base:            brother-1816, "Noto Sans", sans-serif !default;
$font-family-accent:          alternate-gothic-atf, "Bebas Neue", sans-serif;

:root {
--size--letter-spacing-close: 0.2px;
--size--letter-spacing-wider: 1px;

--size--font-weight-regular: 400;
--size--font-weight-medium: 500;
--size--font-weight-demi-bold: 600;
--size--font-weight-bold: 700;
}

h1 {
  font-family: $font-family-accent;
  font-weight: 400;
  font-size: 48px;
  line-height: 56px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

@mixin h2 {
  font-family: $font-family-accent;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.h2,
h2 {
  @include h2;
}

h3 {
  font-family: $font-family-accent;
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

@mixin h4 {
  font-family: $font-family-accent;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.h4,
h4 {
  @include h4;
}

@mixin h4-5 {
  font-family: $font-family-accent;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.h4-5 {
  @include h4-5;
}

@mixin h5 {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
}

.h5,
h5 {
  @include h5;
}

h6 {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.h7 {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.h8 {
  font-family: $font-family-base;
  font-weight: 700;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.text-body-xlarge {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

.text-body-large {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
}

@mixin text-body-regular {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
}

.text-body-regular {
  @include text-body-regular;
}

@mixin text-body-small {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.text-body-small {
  @include text-body-small;
}

@mixin text-body-xsmall {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
}
.text-body-xsmall {
  @include text-body-xsmall;
}

@mixin text-body-tiny {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.text-body-tiny {
  @include text-body-tiny;
}

@mixin text-eyebrow-regular {
  font-family: $font-family-accent;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.text-eyebrow-regular {
  @include text-eyebrow-regular;
}

.text-eyebrow-small {
  font-family: $font-family-accent;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

@mixin text-interactive-link {
  font-family: $font-family-base;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
  &:hover {
    text-decoration: underline;
    text-decoration-skip-ink: auto;
  }
}

.text-interactive-link {
  @include text-interactive-link;
}

@mixin text-interactive-button {
  font-family: $font-family-accent;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
}

.text-interactive-button {
  @include text-interactive-button;
}

@mixin text-form-label {
  font-family: $font-family-base;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.2px;
}

.text-form-label {
  @include text-form-label;
}

@mixin text-form-label-raised {
  font-family: $font-family-base;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.text-form-label-raised {
  @include text-form-label-raised;
}
