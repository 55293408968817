/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

// @import 'tippy.js/dist/tippy.css';

/* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";


@import "theme/fonts";
@import "theme/palette";
// @import "theme/typography";
@import 'theme/tooltip';
@import "theme/metrics";
@import "theme/forms";
@import "theme/animations";

body {
  background-color: var(--color--brand-dark);
  color: var(--color--brand-light);
  position: relative;
  line-height: 1;
  //margin-top: env(safe-area-inset-top) !important;
}

ion-content {
  h1, h2, h3, h4, h5, h6, div, span {
    color: var(--color--brand-light);
  }
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}

input {
  box-sizing: border-box;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
.access-nachas-button{
  .wrapper--button{
      .label {
          display: flex;
      }
  }
}
.set-color{
  .icon-set{
    color: var(--color--brand-cta) !important;
  }
}
.unset-color{
  .icon-set{
    color: var(--color--brand-highlight) !important;
  }
}
.alert-success {
  circle {
    stroke: var(--color--system-green) !important;
  }
  path {
    stroke: var(--color--system-green) !important;
  }
}
app-toast {
  width: var(--size--form-base-width);
  display: inline-block;
  text-align: center;
}
.alert-unset {
  circle {
    stroke: var(--color--brand-highlight) !important;
  }
  path {
    stroke: var(--color--brand-highlight) !important;
  }
}
.checkmark-list-main {
  .checkmark-list {
    app-icon {
      svg{
        width: var(--metric-x4);
        path{
          stroke: #E6E1D7 !important;
        }
      }
    }
  }
  .set-color {
    app-icon {
      svg{
        width: var(--metric-x4);
        path{
          stroke: var(--color--brand-cta) !important;
        }
      }
    }
  }
}
.server-error-msg {
  app-icon {
    svg {
      width: var(--metric-x4);
    }
  }
}



.ion-color-picker {
  --ion-color-base: var(--color--brand-cta);
  --ion-color-base-rgb: 39, 166, 154;
  color: black !important;
}



.mpToast{
  color: var(--color--brand-light);
}

.calendar-day{
  color: black !important;
}

// ion-modal {
//   &.bottom-end {
//     align-items: flex-end;
//     --height: auto;
//     .ion-page {
//       position: relative;
//       display: block;
//       contain: content;
//     }
//   }
// }
